import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

import { CenteredLoadingSpinner } from '~/components/shared';

export function LoginPage() {
  const { loginWithRedirect } = useAuth0();
  useEffect(() => {
    loginWithRedirect();
  }, []);

  return <CenteredLoadingSpinner />;
}
